import { isNil } from 'lodash';

import {
  CreateRegulationRequest,
  CreateRegulationResponse,
  Regulation,
  RegulationCatalogItem
} from '../../models';

import {
  get, post
} from '../OdataApiService';
import { buildODataQuery } from '../utils/odataQueryBuilder';

// Processor API endpoint constants
export const POST_ADD_REGULATION_API = '/AddRegulationActionRequest';
export const POST_GET_REGULATION_API = '/GetRegulationActionRequest';

// Data API endpoint constants
export const GET_REGULATIONS_DATA = '/data/Regulation';
export const GET_REGULATION_CATALOG_DATA = '/data/RegulationCatalog';

/** The Regulation Management API */
export namespace RegulationManagementApi {

  /**
   * Adds a new regulation.
   * @param {CreateRegulationRequest} request - The request to create the new Regulation.
   * @returns {CreateRegulationResponse} The response from the backend Processor
   * @see CreateRegulationRequest
   * @see CreateRegulationResponse
   */
  export const addNewRegulationAsync = async (request: CreateRegulationRequest): Promise<CreateRegulationResponse> => {
    const responseFromApi = await post<CreateRegulationRequest, CreateRegulationResponse>(POST_ADD_REGULATION_API, request);
    return responseFromApi;
  };

  /**
   * Gets Regulations from the backend.
   * @param RegulationGuid - If specified, will add a filter to the OData query to only return results for the particular GUID.
   * @returns {Regulation[]} The Regulation(s) returned from the query.
   */
  export const getRegulations = async (RegulationGuid?: string): Promise<Regulation[]> => {
    const odataConfig: any = {};

    if (!isNil(RegulationGuid)) {
      odataConfig.filter.RegulationGuid = {
        value: RegulationGuid,
        type: 'guid'
      };
    }

    const odataQuery = buildODataQuery(odataConfig);

    const config = {
      odataQuery
    };

    return await get<Regulation[]>(
      GET_REGULATIONS_DATA,
      config
    );
  };

  /**
   * Gets Regulation Catalog Items from the backend. RegulationCatalogItem is a SQL View of the Regulation table.
   * @param RegulationGuid - If specified, will add a filter to the OData query to only return results for the particular GUID.
   * @returns {RegulationCatalogItem[]} The RegulationCatalogItem(s) returned from the query.
   * @see RegulationCatalogItem
   */
  export const getRegulationCatalog = async (RegulationGuid?: string): Promise<RegulationCatalogItem[]> => {
    const odataConfig: any = {};

    if (!isNil(RegulationGuid)) {
      odataConfig.filter.RegulationGuid = {
        value: RegulationGuid,
        type: 'guid'
      };
    }

    const odataQuery = buildODataQuery(odataConfig);

    const config = {
      odataQuery
    };

    return await get<RegulationCatalogItem[]>(
      GET_REGULATION_CATALOG_DATA,
      config
    );
  };
}
