import React from 'react';

import {
  isEmpty, isUndefined
} from 'lodash';
import intl from 'react-intl-universal';

import { MAX_FILTER_ITEM_CHARACTER_LENGTH } from './constants';
import {
  AppliedFiltersProps,
  FilterOption
} from './types';

import { LocIds } from '../../../../../common/Globalization/IntlEnum';
import { CollapsedFilter } from '../../../../../components';
import { TruncatedFilters } from '../../../../../components/templates/FilterLayout';


export const AppliedFilters: React.FC<AppliedFiltersProps> = (props) => {

  // Used for certain fields that can grow to be large so we truncate the name of item for making the UI more user friendly and not broken
  const truncateItemNames = (items?: string[]) => {
    return items?.map(item => item.length > MAX_FILTER_ITEM_CHARACTER_LENGTH ? item.slice(0, MAX_FILTER_ITEM_CHARACTER_LENGTH) + '...' : item);
  };

  const nameFilter = !isEmpty(props.appliedFilters?.regulationNames) && (
    <CollapsedFilter
      items={truncateItemNames(props.appliedFilters?.regulationNames)}
      label={intl.get(LocIds.RegulationManagement.AppliedFiltersRegulationNames)}
      onClear={() => props.onClear(FilterOption.RegulationNames)}
      onClick={props.openFiltersPanel}
    />
  );

  const regulationGuidFilter = !isEmpty(props.appliedFilters?.regulationGuids) && (
    <CollapsedFilter
      items={props.appliedFilters?.regulationGuids}
      label={intl.get(LocIds.RegulationManagement.AppliedFiltersRegulationGuids)}
      onClear={() => props.onClear(FilterOption.RegulationGuids)}
      onClick={props.openFiltersPanel}
    />
  );

  const categoriesFilter = !isEmpty(props.appliedFilters?.categories) && (
    <CollapsedFilter
      items={props.appliedFilters?.categories}
      label={intl.get(LocIds.RegulationManagement.AppliedFiltersCategories)}
      onClear={() => props.onClear(FilterOption.Categories)}
      onClick={props.openFiltersPanel}
    />
  );

  const documentUrlFilter = !isEmpty(props.appliedFilters?.documentUrls) && (
    <CollapsedFilter
      items={truncateItemNames(props.appliedFilters?.documentUrls)}
      label={intl.get(LocIds.RegulationManagement.AppliedFiltersDocumentUrls)}
      onClear={() => props.onClear(FilterOption.DocumentUrls)}
      onClick={props.openFiltersPanel}
    />
  );

  const createdDateStartFilter = !isUndefined(props.appliedFilters?.createdDateStartDate) && (
    <CollapsedFilter
      items={[((props.appliedFilters?.createdDateStartDate) as Date).toDateString()]}
      label={intl.get(LocIds.RegulationManagement.AppliedFiltersCreateDateStart)}
      onClear={() => props.onClear(FilterOption.CreatedDateStartDate)}
      onClick={props.openFiltersPanel}
    />
  );


  const createdDateEndFilter = !isUndefined(props.appliedFilters?.createdDateEndDate) && (
    <CollapsedFilter
      items={[((props.appliedFilters?.createdDateEndDate) as Date).toDateString()]}
      label={intl.get(LocIds.RegulationManagement.AppliedFiltersCreateDateEnd)}
      onClear={() => props.onClear(FilterOption.CreatedDateEndDate)}
      onClick={props.openFiltersPanel}
    />
  );

  const dueDateStartFilter = !isUndefined(props.appliedFilters?.dueDateStartDate) && (
    <CollapsedFilter
      items={[((props.appliedFilters?.dueDateStartDate) as Date).toDateString()]}
      label={intl.get(LocIds.RegulationManagement.AppliedFiltersDueDateStart)}
      onClear={() => props.onClear(FilterOption.DueDateStartDate)}
      onClick={props.openFiltersPanel}
    />
  );

  const dueDateEndFilter = !isUndefined(props.appliedFilters?.dueDateEndDate) && (
    <CollapsedFilter
      items={[((props.appliedFilters?.dueDateEndDate) as Date).toDateString()]}
      label={intl.get(LocIds.RegulationManagement.AppliedFiltersDueDateEnd)}
      onClear={() => props.onClear(FilterOption.DueDateEndDate)}
      onClick={props.openFiltersPanel}
    />
  );

  return (
    <>
      <TruncatedFilters openFiltersPanel={props.openFiltersPanel} showCount={3}>
        <p className='text-14'>{ `${intl.get(LocIds.Label.Filters)}:` }</p>
        { nameFilter }
        { regulationGuidFilter }
        { categoriesFilter }
        { documentUrlFilter }
        { createdDateStartFilter }
        { createdDateEndFilter }
        { dueDateStartFilter }
        { dueDateEndFilter }
      </TruncatedFilters>
    </>
  );
};
