import React from 'react';

import {
  Route, Routes
} from 'react-router-dom';

import RegulationCatalog from './RegulationCatalog';

import RegulationDetailsPage from '../RegulationDetails';

export const RegulationCatalogPageView = (): JSX.Element => {
  return (
    <Routes>
      <Route element={<RegulationCatalog />} path={''} />
      <Route element={<RegulationDetailsPage />}
        path='regulation/:regulationId'
      />
    </Routes>
  );
};
