import React, {
  useEffect, useMemo, useState
} from 'react';

import {
  DetailsList, DetailsListLayoutMode, IColumn, IconButton, IContextualMenuProps, SelectionMode
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
  isEmpty, isUndefined
} from 'lodash';
import intl from 'react-intl-universal';
import {
  Outlet, useNavigate
} from 'react-router-dom';

import { AppliedFilters } from './Filters/AppliedFilters';
import { FilterPanel } from './Filters/FilterPanel';
import {
  FilterOption, RegulationCatalogFilters, emptyRegulationFilters
} from './Filters/types';
import { RegulationCatalogCommandBar } from './RegulationCatalogCommandBar';

import { LocIds } from '../../../../common/Globalization/IntlEnum';
import {
  FilterLayout, PageLayout
} from '../../../../components';
import { RegulationCatalogItem } from '../../../../models';
import { RegulationManagementApi } from '../../../../services/AthenaRestApiService/RegulationManagmentApiService';
import { showErrorMsg } from '../../../../utils';
import { useTitle } from '../../../../utils/react-hooks/useTitle';


/** Component that creates a list of Regulation catalog items based on the results returned by the OData API in the M365 Compliance Platform.
 *  @see RegulationCatalogItem
 *  @see RegulationManagementApi
 */
const RegulationCatalog: React.FC = () => {

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [regulationCatalogItems, setRegulationCatalogItems] = useState<RegulationCatalogItem[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [columns, setColumns] = useState<IColumn[]>([]);
  const [isFiltersPanelOpen, { setTrue: openFiltersPanel, setFalse: closeFiltersPanel },] = useBoolean(false);
  const [filters, setFilters] = useState<RegulationCatalogFilters>(emptyRegulationFilters);

  const navigate = useNavigate();

  useEffect(() => {
    getRegulations();
    setColumns(buildTableColumns());
  }, []);

  /**  Gets the regulations from the Regulation Management OData API. */
  const getRegulations = async () => {
    try {
      const response = await RegulationManagementApi.getRegulationCatalog();
      setRegulationCatalogItems(response);
    } catch (error) {
      showErrorMsg(error);
    } finally {
      setIsLoading(false);
    }
  };

  const applyFilters = (newFilters: RegulationCatalogFilters) => {
    let filteredRegulationData: RegulationCatalogItem[] = [...regulationCatalogItems];

    if (!isEmpty(newFilters.regulationNames)) {
      filteredRegulationData = filteredRegulationData.filter(item => newFilters.regulationNames?.includes(item.Name));
    }

    if (!isEmpty(newFilters.regulationGuids)) {
      filteredRegulationData = filteredRegulationData.filter(item => newFilters.regulationGuids?.includes(item.RegulationGuid));
    }

    if (!isEmpty(newFilters.categories)) {
      // Split the Categories string into an array and check if any of the categories from the filter match individually
      filteredRegulationData = filteredRegulationData.filter(item => {
        const itemCategories = item.Categories.split(',').map(category => category.trim());
        return newFilters.categories?.some(category => itemCategories.includes(category));
      });
    }

    if (!isEmpty(newFilters.documentUrls)) {
      filteredRegulationData = filteredRegulationData.filter(item => newFilters.documentUrls?.includes(item.DocumentUrl));
    }

    if (!isUndefined(newFilters.createdDateStartDate)) {
      filteredRegulationData = filteredRegulationData.filter(item => new Date(item.CreatedDate) >= new Date(newFilters.createdDateStartDate!));
    }

    if (!isUndefined(newFilters.createdDateEndDate)) {
      filteredRegulationData = filteredRegulationData.filter(item => new Date(item.CreatedDate) <= new Date(newFilters.createdDateEndDate!));
    }

    if (!isUndefined(newFilters.dueDateStartDate)) {
      filteredRegulationData = filteredRegulationData.filter(item => new Date(item.DueByDate) >= new Date(newFilters.dueDateStartDate!));
    }

    if (!isUndefined(newFilters.dueDateEndDate)) {
      filteredRegulationData = filteredRegulationData.filter(item => new Date(item.DueByDate) <= new Date(newFilters.dueDateEndDate!));
    }

    //setFilteredItems(filteredRegulationData);
    return filteredRegulationData;
  };

  /** Filters the Regulation Catalog Items based on the search query.
               * @param {string} query - The string to filter the items on. This will look at each property in the items to consider all possible filtering. If any property matches, a result is returned.
               * The query is applied after other filters in the filter panel.
               *
               * @param {RegulationCatalogItem[]} catalogItems - The regulations that are to be searched.
               *
               */
  const searchItems = (query: string, catalogItems: RegulationCatalogItem[]) => {
    const queryAsLowercase = query.toLocaleLowerCase();
    const filteredRegulationData = catalogItems.filter(item =>
      item.Name.toLowerCase().includes(queryAsLowercase) ||
      item.Description?.toLowerCase().includes(queryAsLowercase) ||
      item.Categories?.toLowerCase().includes(queryAsLowercase) ||
      item.DocumentUrl?.toLowerCase().includes(queryAsLowercase) ||
      new Date(item.CreatedDate).toLocaleDateString().includes(queryAsLowercase) ||
      new Date(item.DueByDate).toLocaleDateString().includes(queryAsLowercase)
    );

    return filteredRegulationData;
  };

  const filteredItems: RegulationCatalogItem[] = useMemo((): RegulationCatalogItem[] => {
    if (!isLoading) {
      const filteredRegulationItems = applyFilters(filters);

      // if there is a search query as well, perform the search on the now filtered data
      if (searchQuery) {
        return searchItems(searchQuery, filteredRegulationItems);
      }

      return filteredRegulationItems;
    }

    // if it is still loading, just return the blank array of items
    return regulationCatalogItems;
  }, [
    regulationCatalogItems,
    searchQuery,
    isLoading,
    filters
  ]);

  useTitle(intl.get(LocIds.Title.RegulationManagement));


  const handleViewRegulationClick = (regulationId: string) => {
    navigate(`regulation/${regulationId}`);
  };

  const handleCopyLinkClick = (regulationId: string) => {
    navigator.clipboard.writeText(window.location.href.concat(`/regulation/${regulationId}`));
  };


  /** Creates the array of IColumn objects to use within the table. */
  const buildTableColumns = (): IColumn[] => {
    return [
      {
        key: 'regulationGuid',
        name: intl.get(LocIds.RegulationManagement.RegulationCatalogTableColumnHeaderRegulationGuid),
        minWidth: 200,
        maxWidth: 240,
        fieldName: 'RegulationGuid',
        isResizable: true,
        isPadded: true,
        onColumnClick: _onColumnClick
      },
      {
        key: 'name',
        name: intl.get(LocIds.RegulationManagement.RegulationCatalogTableColumnHeaderRegulationName),
        minWidth: 100,
        maxWidth: 200,
        fieldName: 'Name',
        isResizable: true,
        onColumnClick: _onColumnClick
      },
      {

        key: 'description',
        name: intl.get(LocIds.RegulationManagement.RegulationCatalogTableColumnHeaderRegulationDescription),
        minWidth: 200,
        maxWidth: 400,
        fieldName: 'Description',
        isResizable: true,
        onColumnClick: _onColumnClick
      },
      {
        key: 'categories',
        name: intl.get(LocIds.RegulationManagement.RegulationCatalogTableColumnHeaderRegulationCategories),
        minWidth: 100,
        maxWidth: 100,
        fieldName: 'Categories',
        isResizable: true,
        onColumnClick: _onColumnClick
      },
      {
        key: 'documentUrl',
        name: intl.get(LocIds.RegulationManagement.RegulationCatalogTableColumnHeaderRegulationDocumentUrl),
        minWidth: 300,
        maxWidth: 500,
        fieldName: 'DocumentUrl',
        isResizable: true,
        onColumnClick: _onColumnClick
      },
      {
        key: 'createdDate',
        name: intl.get(LocIds.RegulationManagement.RegulationCatalogTableColumnHeaderRegulationCreatedDate),
        minWidth: 100,
        maxWidth: 200,
        fieldName: 'CreatedDate',
        isResizable: true,
        onRender: (item: RegulationCatalogItem) => {
          return new Date(item.CreatedDate).toLocaleDateString();
        },
        onColumnClick: _onColumnClick
      },
      {
        key: 'dueByDate',
        name: intl.get(LocIds.RegulationManagement.RegulationCatalogTableColumnHeaderRegulationDueDate),
        minWidth: 100,
        maxWidth: 200,
        fieldName: 'DueByDate',
        isResizable: true,
        onRender: (item: RegulationCatalogItem) => {
          return new Date(item.DueByDate).toLocaleDateString();
        },
        onColumnClick: _onColumnClick
      },
      {
        key: 'openLink',
        name: '',
        minWidth: 50,
        maxWidth: 50,
        onRender: (item: RegulationCatalogItem) => {
          const contextualMenuProps: IContextualMenuProps = {
            items: [
              {
                key: 'view',
                iconProps: {
                  iconName: 'View'
                },
                text: intl.get(LocIds.Action.View),
                onClick: () => handleViewRegulationClick(item.RegulationGuid)
              },
              {
                key: 'edit',
                iconProps: {
                  iconName: 'Edit'
                },
                text: intl.get(LocIds.Action.Edit),
                onClick: () => { } // TODO: Implement edit button functionality in a future feature implementation.
              },
              {
                key: 'copylink',
                iconProps: {
                  iconName: 'Copy'
                },
                text: intl.get(LocIds.Action.CopyLink),
                onClick: () => handleCopyLinkClick(item.RegulationGuid)
              },
            ],
          };

          return (
            <IconButton iconProps={{
              iconName: 'More'
            }}
            menuProps={contextualMenuProps}
            title={intl.get(LocIds.Label.MoreAction)}
            />
          );
        }
      }
    ];
  };

  /** Event handler for clicking the header of a column.
                   * @param {React.MouseEvent<HTMLElement>} event - The event of the mouse click.
                   * @param {IColumn} column - The column that was clicked.
                   */
  const _onColumnClick = (
    event: React.MouseEvent<HTMLElement>,
    column: IColumn,
  ): void => {

    setRegulationCatalogItems(prevItems => {
      let sortedRegulationCatalogItems = [...prevItems];
      let isSortedDescending = column.isSortedDescending;

      // If we've sorted this column, flip it.
      if (column.isSorted) {
        isSortedDescending = !isSortedDescending;
      }

      // Sort the items.
      sortedRegulationCatalogItems = _copyAndSort(
        sortedRegulationCatalogItems,
        column.fieldName || '',
        isSortedDescending,
      );

      // Update columns state
      setColumns(prevColumns => {
        return prevColumns.map((col) => {
          col.isSorted = col.key === column.key;

          if (col.isSorted) {
            col.isSortedDescending = isSortedDescending;
          }

          return col;
        });
      });

      return sortedRegulationCatalogItems;
    });
  };

  /** Function to sort the regulation items in the catalog.
                   * @param {RegulationCatalogItem[]} items - The Regulation Catalog Items.
                   * @param {string} columnKey - The key of the IColumn within the table.
                   * @param {boolean} isSortedDescending - [Optional] Boolean to indicate if the data is currently sorted descending or not. If it is sorted descending, we just flip the result of the comparison between each item.
                   */
  const _copyAndSort = (
    items: RegulationCatalogItem[],
    columnKey: string,
    isSortedDescending?: boolean,
  ): RegulationCatalogItem[] => {
    const key = columnKey as keyof RegulationCatalogItem;
    return items
      .slice(0)
      .sort((a: RegulationCatalogItem, b: RegulationCatalogItem) => {
        if (a[key] === b[key]) {
          return 0;
        }

        if (isSortedDescending) {
          if (a[key] < b[key]) {
            return 1;
          }

          return -1;
        }

        // otherwise, assume ascending order is currently true
        if (a[key] < b[key]) {
          return -1;
        }

        return 1;
      });
  };

  const handleApplyFilters = (newFilters: RegulationCatalogFilters) => {
    setFilters(newFilters);
  };

  const handleClearFilters = () => {
    setFilters(emptyRegulationFilters);
  };

  const handleClearIndividualFilter = (filterOption: FilterOption) => {
    switch (filterOption) {
    case FilterOption.RegulationNames:
      setFilters(prevFilters => ({
        ...prevFilters,
        regulationNames: []
      }));
      break;

    case FilterOption.RegulationGuids:
      setFilters(prevFilters => ({
        ...prevFilters,
        regulationGuids: []
      }));
      break;

    case FilterOption.DueDateStartDate:
      setFilters(prevFilters => ({
        ...prevFilters,
        dueDateStartDate: undefined
      }));
      break;

    case FilterOption.DueDateEndDate:
      setFilters(prevFilters => ({
        ...prevFilters,
        dueDateEndDate: undefined
      }));
      break;

    case FilterOption.CreatedDateStartDate:
      setFilters(prevFilters => ({
        ...prevFilters,
        createdDateStartDate: undefined
      }));
      break;

    case FilterOption.CreatedDateEndDate:
      setFilters(prevFilters => ({
        ...prevFilters,
        createdDateEndDate: undefined
      }));
      break;
    case FilterOption.Categories:
      setFilters(prevFilters => ({
        ...prevFilters,
        categories: []
      }));
      break;
    case FilterOption.DocumentUrls:
      setFilters(prevFilters => ({
        ...prevFilters,
        documentUrls: []
      }));
      break;
    default:

    }
  };

  return (
    <PageLayout pageTitle={intl.get(LocIds.RegulationManagement.RegulationCatalog)}>
      <FilterLayout
        hideFilterLabel={true}
        onRenderFilters={() => (
          <AppliedFilters appliedFilters={filters} openFiltersPanel={openFiltersPanel} onClear={handleClearIndividualFilter} />
        )}>
        <RegulationCatalogCommandBar numTotal={filteredItems.length} onSearch={setSearchQuery} />
      </FilterLayout>

      <DetailsList
        columns={columns}
        items={filteredItems}
        layoutMode={DetailsListLayoutMode.justified}
        selectionMode={SelectionMode.none}
      />
      <FilterPanel
        currentFilters={filters}
        isOpen={isFiltersPanelOpen}
        regulationCatalogItems={regulationCatalogItems}
        onApply={handleApplyFilters}
        onClear={handleClearFilters}
        onDismiss={closeFiltersPanel}
      />
      <Outlet />
    </PageLayout>
  );
};

export default RegulationCatalog;
