import React, { lazy } from 'react';

import { RouteObject } from 'react-router-dom';

import {
  Application, RegulationManagement
} from '../../TrustPortal/registerApplicationMenu';
import RegulationCatalog from '../pages/RegulationCatalog';

const Home = lazy(() => import('../pages/Home'));
const NewRegulation = lazy(() => import('../pages/NewRegulation'));

export const routes: RouteObject[] = [
  {
    path: `${Application.RegulationManagement}/*`,
    element: <Home />,
    children: [
      {
        path: `menu/${RegulationManagement.NewRegulation}/*`,
        element: <NewRegulation />
      },
      {
        path: `menu/${RegulationManagement.RegulationCatalog}/*`,
        element: <RegulationCatalog />
      }
    ],
  },
];
