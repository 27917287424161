/* eslint-disable i18next/no-literal-string */
import { RegulationCatalogItem } from '../../../../../models';

export interface AppliedFiltersProps {
  openFiltersPanel: () => void;
  onClear: (filterToClear: FilterOption) => void;
  appliedFilters?: RegulationCatalogFilters;
}

// The props for a filter panel on the Regulation Catalog
export interface FilterPanelProps {
  regulationCatalogItems: RegulationCatalogItem[];
  currentFilters: RegulationCatalogFilters;
  isOpen: boolean;
  onApply: (filters: RegulationCatalogFilters) => void;
  onClear: () => void;
  onDismiss: () => void;
}

export interface RegulationCatalogFilters {
  regulationNames?: string[];
  regulationGuids?: string[];
  dueDateStartDate?: Date | null;
  dueDateEndDate?: Date | null;
  createdDateStartDate?: Date | null;
  createdDateEndDate?: Date | null;
  categories?: string[];
  documentUrls?: string[];
}

// default state to clear filters in different components (or to initialize things to a blank state)
export const emptyRegulationFilters: RegulationCatalogFilters = {
  regulationNames: [],
  regulationGuids: [],
  dueDateStartDate: undefined,
  dueDateEndDate: undefined,
  createdDateStartDate: undefined,
  createdDateEndDate: undefined,
  categories: [],
  documentUrls: []
};

export enum FilterOption {
  RegulationNames,
  RegulationGuids,
  DueDateStartDate,
  DueDateEndDate,
  CreatedDateStartDate,
  CreatedDateEndDate,
  Categories,
  DocumentUrls
}

export const CategoryOptions: string[] = [
  'AI',
  'Cybersecurity',
  'Privacy',
  'DigitalSafety',
  'LawfulAccess',
  'Other'
];
