import React from 'react';

import { DateRangePicker } from '../../../../../components';

interface DateRangeFilterProps {
    onFilterChange: (dates: { startDate?: Date | null, endDate?: Date | null }) => void;
    startDate?: Date | null,
    endDate?: Date | null,
    startDateLabel?: string,
    endDateLabel?: string
}

export const DateRangeFilter: React.FC<DateRangeFilterProps> = (props) => {
  const { onFilterChange, startDate, endDate } = props;

  const onChangeStartDate = (startDate: Date | null | undefined) => {
    onFilterChange({
      startDate: startDate ?? undefined,
      endDate
    });
  };
  const onChangeEndDate = (endDate: Date | null | undefined) => {
    onFilterChange({
      startDate,
      endDate: endDate ?? undefined
    });
  };

  return (
    <DateRangePicker
      allowClear={true}
      endDate={endDate ?? undefined}
      endDateLabel={props.endDateLabel}
      startDate={startDate ?? undefined}
      startDateLabel={props.startDateLabel}
      onSelectEndDate={onChangeEndDate}
      onSelectStartDate={onChangeStartDate}
    />
  );
};
