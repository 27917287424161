import { FilterOptions } from '../models/ComplianceViewer';

export function createUniqueOptionsList(filterName: string, filteredList: FilterOptions[]): string[] {
  const filterSet = new Set<string>();
  filteredList.forEach((filterOption) => {
    if (filterOption[filterName as keyof FilterOptions] !== undefined) {
      filterSet.add(filterOption[filterName as keyof FilterOptions] as string);
    }
  });
  return Array.from(filterSet).sort();
}
