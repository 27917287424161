import React, { useEffect } from 'react';

import { isNil } from 'lodash';
import { useParams } from 'react-router-dom';

import { notify } from '../../../../utils';

const RegulationDetailsPage: React.FC = () => {

  /**
     * regulationId that we are looking at will come from the regulation in the URL when the link is clicked. We will need an API that goes and gets the Regulation data too.
     *  Keep in mind that we will need multiple tables showing up in this page, so we will want to create an SQL View, API, etc.
     */

  const { regulationId } = useParams();

  useEffect(() => {
    if (isNil(regulationId)) {
      notify.error('Error loading regulation id. In this case, we\'ll need to determine if the page is just blank, or if we want to do just display an error.');
    }
  }, []);

  return (
    <>
      <h1>Regulation Details</h1>
      <p>{ regulationId }</p>
    </>
  );
};

export default RegulationDetailsPage;
