import React, {
  useEffect, useMemo, useState
} from 'react';

import _ from 'lodash';

import intl from 'react-intl-universal';

import { DateRangeFilter } from './DateRangeFilter';
import {
  CategoryOptions, FilterPanelProps, RegulationCatalogFilters, emptyRegulationFilters
} from './types';

import { LocIds } from '../../../../../common/Globalization/IntlEnum';
import {
  BaseFilterPanel, FilterFieldWrapper, MultiSelectDropdown
} from '../../../../../components';


export const FilterPanel: React.FC<FilterPanelProps> = (props) => {

  const [selectedFilters, setSelectedFilters] = useState<RegulationCatalogFilters>({
    ...emptyRegulationFilters
  });

  useEffect(() => {
    if (props.isOpen) {
      setSelectedFilters(props.currentFilters);
    }
  }, [props.isOpen, props.currentFilters]);

  const hasChanged = useMemo(() => {
    return !_.isEqual(props.currentFilters, selectedFilters);
  }, [props.currentFilters, selectedFilters]);

  const handleDueDateRangeChange = (dates: { startDate?: Date | null, endDate?: Date | null }) => {
    setSelectedFilters(prevOptions => ({
      ...prevOptions,
      dueDateStartDate: dates.startDate,
      dueDateEndDate: dates.endDate
    }));
  };

  const handleCreatedDateRangeChange = (dates: { startDate?: Date | null, endDate?: Date | null }) => {
    setSelectedFilters(prevOptions => ({
      ...prevOptions,
      createdDateStartDate: dates.startDate,
      createdDateEndDate: dates.endDate
    }));
  };

  const onChangeRegulationNames = (selectedNames: string[]) => {
    setSelectedFilters(prevOptions => ({
      ...prevOptions,
      regulationNames: selectedNames
    }));
  };

  const onChangeRegulationGuids = (selectedGuids: string[]) => {
    setSelectedFilters(prevOptions => ({
      ...prevOptions,
      regulationGuids: selectedGuids
    }));
  };

  const onChangeCategories = (newCategories: string[]) => {
    setSelectedFilters(prevOptions => ({
      ...prevOptions,
      categories: newCategories
    }));
  };

  const onChangeDocumentUrls = (urls: string[]) => {
    setSelectedFilters(prevOptions => ({
      ...prevOptions,
      documentUrls: urls
    }));
  };

  const onClearAllFilters = () => {
    setSelectedFilters({
      ...emptyRegulationFilters
    });
  };

  const regulationNameFilter = (
    <FilterFieldWrapper
      ariaLabel={intl.get(LocIds.RegulationManagement.FilterPanelRegulationName)}
      isClearActive={!_.isEmpty(selectedFilters?.regulationNames)}
      onClear={() => onChangeRegulationNames([])}>
      <MultiSelectDropdown
        disabled={false}
        items={[...new Set(props.regulationCatalogItems.map(regulationItem => regulationItem.Name))]}
        keyOf={(item) => item}
        label={intl.get(LocIds.RegulationManagement.FilterPanelRegulationName)}
        optionOf={(item) => ({
          key: item,
          text: item
        })}
        placeholder={intl.get(LocIds.Placeholder.SelectToFilter)}
        searchable
        selectedItems={selectedFilters.regulationNames}
        onChange={onChangeRegulationNames}
      />
    </FilterFieldWrapper>
  );

  const regulationGuidFilter = (
    <FilterFieldWrapper
      ariaLabel={intl.get(LocIds.RegulationManagement.FilterPanelRegulationGuid)}
      isClearActive={!_.isEmpty(selectedFilters?.regulationGuids)}
      onClear={() => onChangeRegulationGuids([])}>
      <MultiSelectDropdown
        disabled={false}
        items={props.regulationCatalogItems.map(regulationItem => regulationItem.RegulationGuid)}
        keyOf={(item) => item}
        label={intl.get(LocIds.RegulationManagement.FilterPanelRegulationGuid)}
        optionOf={(item) => ({
          key: item,
          text: item
        })}
        placeholder={intl.get(LocIds.Placeholder.SelectToFilter)}
        searchable
        selectedItems={selectedFilters.regulationGuids}
        onChange={onChangeRegulationGuids}
      />
    </FilterFieldWrapper>
  );

  const createdDateFilter = (
    <DateRangeFilter
      endDate={selectedFilters?.createdDateEndDate}
      endDateLabel={intl.get(LocIds.RegulationManagement.FilterPanelCreateDateEnd)}
      startDate={selectedFilters?.createdDateStartDate}
      startDateLabel={intl.get(LocIds.RegulationManagement.FilterPanelCreateDateStart)}
      onFilterChange={(dates) => handleCreatedDateRangeChange(dates)}
    />
  );

  const dueByDateFilter = (
    <DateRangeFilter
      endDate={selectedFilters?.dueDateEndDate}
      endDateLabel={intl.get(LocIds.RegulationManagement.FilterPanelDueDateEnd)}
      startDate={selectedFilters?.dueDateStartDate}
      startDateLabel={intl.get(LocIds.RegulationManagement.FilterPanelDueDateStart)}
      onFilterChange={(dates) => handleDueDateRangeChange(dates)}
    />
  );

  const categoriesFilter = (
    <FilterFieldWrapper
      ariaLabel={intl.get(LocIds.RegulationManagement.FilterPanelCategories)}
      isClearActive={!_.isEmpty(selectedFilters?.categories)}
      onClear={() => onChangeCategories([])}>
      <MultiSelectDropdown
        disabled={false}
        items={CategoryOptions}
        keyOf={(item) => item}
        label={intl.get(LocIds.RegulationManagement.FilterPanelCategories)}
        optionOf={(item) => ({
          key: item,
          text: item
        })}
        placeholder={intl.get(LocIds.Placeholder.SelectToFilter)}
        searchable
        selectedItems={selectedFilters.categories}
        onChange={onChangeCategories}
      />
    </FilterFieldWrapper>
  );

  const documentUrlFilter = (
    <FilterFieldWrapper
      ariaLabel={intl.get(LocIds.RegulationManagement.FilterPanelDocumentUrl)}
      isClearActive={!_.isEmpty(selectedFilters?.documentUrls)}
      onClear={() => onChangeDocumentUrls([])}>
      <MultiSelectDropdown
        disabled={false}
        items={[...new Set(props.regulationCatalogItems.map(regulationItem => regulationItem.DocumentUrl))]}
        keyOf={(item) => item}
        label={intl.get(LocIds.RegulationManagement.FilterPanelDocumentUrl)}
        optionOf={(item) => ({
          key: item,
          text: item
        })}
        placeholder={intl.get(LocIds.Placeholder.SelectToFilter)}
        searchable
        selectedItems={selectedFilters.documentUrls}
        onChange={onChangeDocumentUrls}
      />
    </FilterFieldWrapper>
  );


  return (
    <>
      <BaseFilterPanel
        hidePanel={() => props.onDismiss()}
        isChanged={hasChanged}
        isOpen={props.isOpen}
        onApply={() => props.onApply(selectedFilters)}
        onClear={onClearAllFilters}>
        { regulationNameFilter }
        { regulationGuidFilter }
        { createdDateFilter }
        { dueByDateFilter }
        { categoriesFilter }
        { documentUrlFilter }
      </BaseFilterPanel >
    </>
  );

};
