import React, { useState } from 'react';

import intl from 'react-intl-universal';

import { LocIds } from '../../../../common/Globalization/IntlEnum';
import { FilterableCommandBar } from '../../../../components';

export type RegulationCatalogCommandBarProps = {
    numTotal: number;
    onSearch: (keyword: string) => void;
}

export const RegulationCatalogCommandBar: React.FC<RegulationCatalogCommandBarProps> = (props) => {

  const [keyword, setKeyword] = useState<string>('');

  const keywordProps = {
    keyword: keyword ? keyword : '',
    onKeywordChange: (keyword: string) => {
      setKeyword(keyword);
      props.onSearch(keyword);
    }
  };

  return (
    <FilterableCommandBar
      // TODO: Implement SortProps as an enhancement once we implement the Card component UI (for now we will just use the DetailsList column sorting implementation)
      //sortProps={sortProps}
      keywordProps={keywordProps}
      totalText={intl.get(LocIds.Label.NumberItems, {
        num: props.numTotal
      })}
    />
  );

};
