/* eslint-disable i18next/no-literal-string */

import { OdataQuery } from 'odata';

import {
  Certification, EligibilityError, LifecycleStatus, ServiceHierarchyNodeType, ServiceRing, ServiceTypeClassification, SubscriptionCloud, SubstrateModel
} from '../../applications/FedHound/constants/Enums';
import {
  DiscoverySummary,
  FedHoundContactBusinessContinuityLead,
  FedHoundDatasetItem,
  FedHoundPermissions,
  ServiceAnomalyDetails,
  FedHoundServiceDetails,
  ServiceSummary,
  FedHoundServiceTestSummary,
  UpdateTestRenewalStatus,
  FedHoundGovSubscriptionMappingSummary,
  CreateOrUpdateManualGovCloudSubscriptionMappingRequest,
  CreateOrUpdateManualServiceTestCloudMappingRequest,
  ServiceTestForGccMtReview,
  CreateOrUpdateFedHoundServiceAuditScopesRequest,
  FedHoundServiceAuditScopesDetails,
  CreateUpdateDeleteManualServiceAnomalyOverrideRequest,
} from '../../models';
import {
  BcdrEligibilityData, BcdrOnboardingDataForReview, BcdrOnboardingFormResponse
} from '../../models/FedHound/OnboardingTypes';
import {
  CommandResult,
  FedRampApproveTestRenewalCommandParams,
  FedRampManualCloudOverrideSearchParams,
  FedRampMarkTestCompleteCommandParams,
  FedRampSearchManualGovSubscriptionMappingSearchParams,
  FedRampUpdateManualGovSubscriptionMappingCommandParams,
  DefaultGuid,
  SortOrderValues,
  FedRampServiceWithManualOverrideItemPagedListResults,
  FedRampForceRenewTestsRequest,
  FedRampValidateTestCommandParams,
  CreateOrUpdateAnomalyAcknowledgement,
} from '../../models/M365BcmToolModels';

import { sortingOrder } from '../../utils';
import {
  get,
  post
} from '../OdataApiService';
import {
  buildOrderByString,
  fieldMatchOnly,
} from '../utils/odataQueryBuilder';

export const GET_FEDHOUND_CONTACT_BUSINESS_CONTINUITY_LEAD = '/data/FedHoundContactBusinessContinuityLead';
export const GET_FEDHOUND_DATASET = '/data/GetFedHoundDatasetRequest';
export const GET_FEDHOUND_PERMISSIONS = '/data/GetFedHoundPermissionsRequest';
export const GET_FEDRAMP_MANUAL_GOV_CLOUD_MAPPINGS = '/data/FedRampGetManualGovCloudMappingRequest';
export const GET_FEDRAMP_SEARCH_MANUAL_GOV_CLOUD_MAPPING = '/data/FedRampGetManualGovCloudMappingSearchRequest';
export const GET_SERVICE_SUMMARY = '/data/GetServiceSummary';
export const GET_SERVICES_VALID_FOR_AUDIT = '/data/GetServicesValidForAudit';
export const GET_FEDHOUND_DISCOVERY_SUMMARY = '/data/FedHoundDiscoverySummary';
export const GET_FEDHOUND_SERVICE_DETAILS = '/data/FedHoundServiceDetails';
export const GET_FEDHOUND_SERVICE_ANOMALY_DETAILS = '/data/FedHoundServiceAnomalyDetails';
export const GET_FEDHOUND_SERVICE_TEST_SUMMARY = '/data/FedHoundServiceTestSummary';
export const GET_FEDHOUND_GOV_SUBSCRIPTION_MAPPING_SUMMARY = '/data/FedHoundGovSubscriptionMappingSummary';
export const GET_SERVICE_TEST_FOR_GCCMT_REVIEW = '/data/ServiceTestForGccMtReview';
export const GET_FEDHOUND_SERVICE_AUDIT_SCOPES_DETAILS = '/data/FedHoundServiceAuditScopesDetails';
export const GET_SERVICE_ELIGIBILITY_RESULT = '/data/ServiceEligibilityResult';

export const UPDATE_TEST_RENEWAL_STATUS = '/data/UpdateTestRenewalStatus';

export const MARK_TEST_VALID = '/data/FedHoundMarkTestValidRequest';
export const FORCE_RENEW_FEDRAMP_TESTS = '/data/FedRampForceRenewTestsRequest';
export const MARK_TEST_COMPLETE = '/data/FedRampMarkTestCompleteRequest';
export const APPROVE_TEST_RENEWAL = '/data/FedRampApproveTestRenewalRequest';
export const UPDATE_FEDRAMP_MANUAL_GOV_CLOUD_MAPPING = '/data/FedRampUpdateManualGovCloudMappingRequest';
export const CREATE_OR_UPDATE_ANOMALY_ACKNOWLEDGEMENT = '/data/CreateOrUpdateAnomalyAcknowledgement';
export const CREATE_OR_UPDATE_MANUAL_GOV_CLOUD_SUBSCRIPTION_MAPPING = '/data/CreateOrUpdateManualGovCloudSubscriptionMappingRequest';
export const CREATE_OR_UPDATE_MANUAL_SERVICE_TEST_CLOUD_MAPPING = '/data/CreateOrUpdateManualServiceTestCloudMappingRequest';
export const CREATE_OR_UPDATE_FEDHOUND_SERVICE_AUDIT_SCOPES =
  '/data/CreateOrUpdateFedHoundServiceAuditScopesRequest';
export const CREATE_UPDATE_DELETE_MANUAL_SERVICE_ANOMALY_OVERRIDE =
  '/data/CreateUpdateDeleteManualServiceAnomalyOverrideRequest';

export const M365BcmToolApi = {
  async getFedHoundContactBusinessContinuityLeadAsync(): Promise<FedHoundContactBusinessContinuityLead[]> {
    const odataQuery: OdataQuery = {
      $orderby: buildOrderByString(('DisplayName' + ' ' + sortingOrder.ASCENDING))
    };
    const config = {
      odataQuery,
    };
    const data = await get<FedHoundContactBusinessContinuityLead[]>(GET_FEDHOUND_CONTACT_BUSINESS_CONTINUITY_LEAD, config);
    return data;
  },

  async getServiceSummaryAsync(bclAccountId: string): Promise<ServiceSummary[]> {
    const searchParams = {
      businessContinuityLeadAccountGuid: bclAccountId
    };
    const data = await post<any, ServiceSummary[]>(GET_SERVICE_SUMMARY, searchParams);
    return data;
  },

  async getServicesValidForAuditAsync(bclAccountId: string): Promise<string[]> {
    const searchParams = {
      businessContinuityLeadAccountGuid: bclAccountId
    };
    const data = await post<any, string[]>(GET_SERVICES_VALID_FOR_AUDIT, searchParams);
    return data;
  },

  async getDiscoverySummaryAsync(
    businessContinuityLeadAccountGuid: string,
  ): Promise<DiscoverySummary[]> {
    const odataQuery: OdataQuery = {
      $filter: fieldMatchOnly(
        {
          value: businessContinuityLeadAccountGuid,
          type: 'guid'
        },
        'BusinessContinuityLeadAccountGuid',
      ),
    };
    const config = {
      odataQuery,
    };
    const data = await get<DiscoverySummary[]>(GET_FEDHOUND_DISCOVERY_SUMMARY, config);
    return data;
  },

  async getServiceDetailsAsync(
    serviceTreeId: string,
  ): Promise<FedHoundServiceDetails> {
    const odataQuery: OdataQuery = {
      $filter: fieldMatchOnly(
        {
          value: serviceTreeId,
          type: 'guid'
        },
        'ServiceTreeId',
      ),
    };
    const config = {
      odataQuery,
    };
    const data = await get<FedHoundServiceDetails[]>(GET_FEDHOUND_SERVICE_DETAILS, config);
    return data[0];
  },

  async getServiceAnomalyDetailsAsync(
    serviceTreeId: string,
  ): Promise<ServiceAnomalyDetails | undefined> {
    const odataQuery: OdataQuery = {
      $filter: fieldMatchOnly(
        {
          value: serviceTreeId,
          type: 'guid'
        },
        'ServiceTreeId',
      ),
    };
    const config = {
      odataQuery,
    };
    const data = await get<ServiceAnomalyDetails[]>(GET_FEDHOUND_SERVICE_ANOMALY_DETAILS, config);
    return data[0];
  },

  async getServiceTestSummaryAsync(
    serviceTreeId: string,
  ): Promise<FedHoundServiceTestSummary[]> {
    const odataQuery: OdataQuery = {
      $filter: fieldMatchOnly(
        {
          value: serviceTreeId,
          type: 'guid'
        },
        'ServiceTreeId',
      ),
    };
    const config = {
      odataQuery,
    };
    const data = await get<FedHoundServiceTestSummary[]>(GET_FEDHOUND_SERVICE_TEST_SUMMARY, config);
    return data;
  },

  async getFedHoundGovSubscriptionMappingSummaryAsync(
    businessContinuityLeadAccountGuid: string,
  ): Promise<FedHoundGovSubscriptionMappingSummary[]> {
    const odataQuery: OdataQuery = {
      $filter: fieldMatchOnly(
        {
          value: businessContinuityLeadAccountGuid,
          type: 'guid'
        },
        'BusinessContinuityLeadAccountGuid',
      ),
    };
    const config = {
      odataQuery,
    };
    const data = await get<FedHoundGovSubscriptionMappingSummary[]>(GET_FEDHOUND_GOV_SUBSCRIPTION_MAPPING_SUMMARY, config);
    return data;
  },

  async getServiceTestForGccMtReviewAsync(
    businessContinuityLeadAccountGuid: string,
  ): Promise<ServiceTestForGccMtReview[]> {
    const odataQuery: OdataQuery = {
      $filter: fieldMatchOnly(
        {
          value: businessContinuityLeadAccountGuid,
          type: 'guid'
        },
        'BusinessContinuityLeadAccountGuid',
      ),
    };
    const config = {
      odataQuery,
    };
    const data = await get<ServiceTestForGccMtReview[]>(GET_SERVICE_TEST_FOR_GCCMT_REVIEW, config);
    return data;
  },

  async getFedHoundServiceAuditScopesDetailsAsync(
    businessContinuityLeadAccountGuid: string,
  ): Promise<FedHoundServiceAuditScopesDetails[]> {
    const odataQuery: OdataQuery = {
      $filter: fieldMatchOnly(
        {
          value: businessContinuityLeadAccountGuid,
          type: 'guid'
        },
        'BusinessContinuityLeadAccountGuid',
      ),
    };
    const config = {
      odataQuery,
    };
    const data = await get<FedHoundServiceAuditScopesDetails[]>(GET_FEDHOUND_SERVICE_AUDIT_SCOPES_DETAILS, config);
    return data;
  },

  async getFedHoundDatasetAsync(): Promise<FedHoundDatasetItem[]> {
    const data = await post<Record<string, unknown>,
      FedHoundDatasetItem[]>(GET_FEDHOUND_DATASET, {});
    return data;
  },

  async getFedHoundPermissionsAsync(): Promise<FedHoundPermissions> {
    const data = await post<Record<string, unknown>,
      FedHoundPermissions>(GET_FEDHOUND_PERMISSIONS, {});
    return data;
  },

  async getFedRampManualGovSubscriptionMappingsAsync(quickSearch?: string): Promise<FedRampServiceWithManualOverrideItemPagedListResults> {
    const searchParams: FedRampManualCloudOverrideSearchParams = {
      quickSearch: quickSearch ?? DefaultGuid.DefaultGuid,
      pageIndex: 1,
      pageSize: 1000,
      sortBy: 'UpdatedDate',
      sortOrder: SortOrderValues.Descending
    };
    const data = await post<FedRampManualCloudOverrideSearchParams,
      FedRampServiceWithManualOverrideItemPagedListResults>(GET_FEDRAMP_MANUAL_GOV_CLOUD_MAPPINGS, searchParams);
    return data;
  },

  async getFedRampSearchServiceForManualGovSubscriptionMappingAsync(searchParam: FedRampSearchManualGovSubscriptionMappingSearchParams):
    Promise<FedRampServiceWithManualOverrideItemPagedListResults> {
    const data = await post<FedRampSearchManualGovSubscriptionMappingSearchParams,
      FedRampServiceWithManualOverrideItemPagedListResults>(GET_FEDRAMP_SEARCH_MANUAL_GOV_CLOUD_MAPPING, searchParam);
    return data;
  },

  async updateFedRampManualGovSubscriptionMappingCommandAsync(commandParam: FedRampUpdateManualGovSubscriptionMappingCommandParams):
    Promise<CommandResult> {
    const data = await post<FedRampUpdateManualGovSubscriptionMappingCommandParams,
      CommandResult>(UPDATE_FEDRAMP_MANUAL_GOV_CLOUD_MAPPING, commandParam);
    return data;
  },

  async updateTestRenewalStatusAsync(commandParam: UpdateTestRenewalStatus):
    Promise<void> {
    return await post<UpdateTestRenewalStatus, void>(UPDATE_TEST_RENEWAL_STATUS, commandParam);
  },

  async markTestValidAsync(commandParam: FedRampValidateTestCommandParams):
    Promise<void> {
    return await post<FedRampValidateTestCommandParams, void>(MARK_TEST_VALID, commandParam);
  },

  async forceRenewTestsAsync(commandParam: FedRampForceRenewTestsRequest):
    Promise<void> {
    return await post<FedRampForceRenewTestsRequest, void>(FORCE_RENEW_FEDRAMP_TESTS, commandParam);
  },

  async markTestCompleteCommandAsync(commandParam: FedRampMarkTestCompleteCommandParams):
    Promise<void> {
    const response = await post<FedRampMarkTestCompleteCommandParams, void>(MARK_TEST_COMPLETE, commandParam);
    return response;
  },

  async approveTestRenewalCommandAsync(commandParam: FedRampApproveTestRenewalCommandParams):
    Promise<CommandResult> {
    const data = await post<FedRampApproveTestRenewalCommandParams,
      CommandResult>(APPROVE_TEST_RENEWAL, commandParam);
    return data;
  },

  async createOrUpdateAnomalyAcknowledgementAsync(commandParam: CreateOrUpdateAnomalyAcknowledgement):
    Promise<void> {
    return await post<CreateOrUpdateAnomalyAcknowledgement, void>(CREATE_OR_UPDATE_ANOMALY_ACKNOWLEDGEMENT, commandParam);
  },

  async createOrUpdateManualGovCloudSubscriptionMappingAsync(commandParam: CreateOrUpdateManualGovCloudSubscriptionMappingRequest):
    Promise<void> {
    return await post<CreateOrUpdateManualGovCloudSubscriptionMappingRequest, void>(
      CREATE_OR_UPDATE_MANUAL_GOV_CLOUD_SUBSCRIPTION_MAPPING,
      commandParam);
  },

  async createOrUpdateManualServiceTestCloudMappingAsync(commandParam: CreateOrUpdateManualServiceTestCloudMappingRequest):
    Promise<void> {
    return await post<CreateOrUpdateManualServiceTestCloudMappingRequest, void>(
      CREATE_OR_UPDATE_MANUAL_SERVICE_TEST_CLOUD_MAPPING,
      commandParam);
  },

  async createOrUpdateFedHoundServiceAuditScopesAsync(
    commandParam: CreateOrUpdateFedHoundServiceAuditScopesRequest):
    Promise<void> {
    return await post<CreateOrUpdateFedHoundServiceAuditScopesRequest, void>(
      CREATE_OR_UPDATE_FEDHOUND_SERVICE_AUDIT_SCOPES,
      commandParam);
  },

  async createUpdateDeleteManualServiceAnomalyOverrideAsync(
    commandParam: CreateUpdateDeleteManualServiceAnomalyOverrideRequest):
    Promise<void> {
    return await post<CreateUpdateDeleteManualServiceAnomalyOverrideRequest, void>(
      CREATE_UPDATE_DELETE_MANUAL_SERVICE_ANOMALY_OVERRIDE,
      commandParam);
  },


  async getBcdrEligibilityDataAsync() {

    const exampleEligibilityResult: BcdrEligibilityData = {
      ServiceTreeId: '0000-00000-0000-0000',
      Name: 'Example Service Name',
      BcdrChamp: 'Champ',
      IsEligible: true,
      BusinessContinuityLead: 'Leader',
      ServiceType: ServiceHierarchyNodeType.Component,
      ServiceTypeClassification: ServiceTypeClassification.Client,
      LifecycleStatus: LifecycleStatus.GA,
      EligibilityErrors: [EligibilityError.NotEligibleWrongBCL, EligibilityError.NotEligibleServiceType]
    };
    return exampleEligibilityResult;

  },

  async getBcdrOnboardingFormResponseAsync() {
    const exampleBcdrOnboardingFormResponse: BcdrOnboardingFormResponse = {
      IsRequestorAlsoBcdrChamp: false,
      IsSubstrateModel: false,
      SubstrateModel: SubstrateModel.A
    };
    return exampleBcdrOnboardingFormResponse;
  },

  async getBcdrOnboardingDataForReviewAsync() {
    const exampleBcdrOnboardingDataForReview: BcdrOnboardingDataForReview = {
      Certifications: [Certification.ISO, Certification.Gcc],
      SubscriptionClouds: [SubscriptionCloud.Blackforest, SubscriptionCloud.Bleu, SubscriptionCloud.Fairfax],
      ServiceCloudLifecycles: {
        [SubscriptionCloud.Public]: LifecycleStatus.GA,
        [SubscriptionCloud.Mooncake]: LifecycleStatus.PrivatePreview,
        [SubscriptionCloud.Fairfax]: LifecycleStatus.InDevelopment,
        [SubscriptionCloud.Blackforest]: LifecycleStatus.PublicPreview,
        [SubscriptionCloud.USSec]: LifecycleStatus.GA,
        [SubscriptionCloud.USNat]: LifecycleStatus.ClosingDown,
        [SubscriptionCloud.Bleu]: LifecycleStatus.Retired,
        [SubscriptionCloud.Delos]: LifecycleStatus.None,
        [SubscriptionCloud.GovSG]: LifecycleStatus.PrivatePreviewSPACE,
      },
      RingInheritance: true, //if true show yes, if false show no
      ServiceRing: ServiceRing.Ring0,
      Offerings: ['Offering 1', 'Example Offering'],
      ProductSKUs: ['Product 1', 'Example Product'],
      IsExternalFacing: false //no for false, yes for true
    };
    return exampleBcdrOnboardingDataForReview;
  },

};
